import PostSkeleton from "./"
import type { GetNoteResp, Blog } from "@types"
import Placeholder from "components/Placeholder"

export default function UngatedPostSkeleton({
  blog,
  currentNote,
}: {
  blog: Blog
  currentNote: GetNoteResp | undefined
}) {
  // May be some slight delay where this is undefined in the store.
  if (!currentNote) {
    return (
      <div className="mt-24 flex justify-center">
        <Placeholder />
      </div>
    )
  }

  return (
    <PostSkeleton
      loading={false}
      currentNote={currentNote}
      blog={blog}
      hasAccess={true}
      gatingRuleGroups={[]}
      hideComments={blog.disable_comments === true}
    />
  )
}
