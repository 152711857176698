import { useEffect } from "react"
import { useTimeOnPage } from "hooks/useTimeOnPage"
import { upsertPostMetric } from "api_routes/metrics"
import { useAnalytics } from "hooks/useAnalytics"
import { Blog, GetNoteResp } from "@types"

export default function usePostPageMetrics({
  currentNote,
  blog,
  preview,
}: {
  currentNote?: GetNoteResp
  blog: Blog
  preview: boolean
}) {
  const { track } = useAnalytics()

  useEffect(() => {
    track("post viewed")

    // Increment the post view metric.
    if (blog && currentNote && !preview) {
      upsertPostMetric(blog.id, currentNote?.id, true)
    }
  }, [])

  // Track how long user has spent on the page.
  const storeTimeOnPageMetric = (finalTimeOnPage: number) => {
    if (!currentNote) return

    console.log("Time on page was ", finalTimeOnPage)
    // This is called when a user has stopped looking at the page, so we're passing in `false` for the boolean to increment the page view counter.
    upsertPostMetric(blog.id, currentNote?.id, false, finalTimeOnPage)
  }
  useTimeOnPage(storeTimeOnPageMetric)
}
