import { useEffect, useRef } from "react"

// Used to track whether the user is actively doing something or is idling (even if the page is actively visible).
export function useUserActiveOnPage() {
  // Have to use useRef because useState isn't updating from within event listeners.
  const isActive = useRef(true)
  // const [lastEventAt, setLastEventAt] = useState(Date.now())
  const lastEventAt = useRef(Date.now())

  const onScroll = () => {
    lastEventAt.current = Date.now()

    if (!isActive.current) {
      console.log(
        "useUserActiveOnPage: The user scrolled! Updating lastEventAt time and setting isIdling to false.",
        { isActive: isActive, lastEventAt }
      )
      isActive.current = true
    }
  }

  const onMouseMove = () => {
    lastEventAt.current = Date.now()

    if (!isActive.current) {
      console.log(
        "useUserActiveOnPage: The user moved their mouse! Updating lastEventAt time and setting isIdling to false.",
        { isActive: isActive, lastEventAt }
      )
      isActive.current = true
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll, true)
    document.addEventListener("mousemove", onMouseMove, true)

    // Every 5 seconds, let's check whether the user has interacted with the page in some way.
    // Whenever 30 seconds has elapsed from the last user event (e.g. a scroll or mouse movement or click)
    // let's flip the idle switch to true.
    const inactivityInterval = setInterval(() => {
      const lastActiveAt = Date.now() - lastEventAt.current
      // const lastActiveAtStr = `${parseFloat(
      //   (lastActiveAt / 1000).toString()
      // ).toFixed(1)}s ago`

      if (lastActiveAt > 30000) {
        // console.log(
        //   "useUserActiveOnPage: At least 30 seconds have elapsed since the user has last done anything. Setting to idling.",
        //   {
        //     lastActive: lastActiveAtStr,
        //   }
        // )
        isActive.current = false
      } else {
        // console.log(
        //   "useUserActiveOnPage: User has been active within the last 30 seconds, doing nothing.",
        //   { lastActive: lastActiveAtStr }
        // )
      }
    }, 5000)

    return () => {
      // Remove event listeners in destructor.
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener("mousemove", onMouseMove)

      clearInterval(inactivityInterval)
    }
  }, [])

  return isActive
}
