import { useSelector } from "react-redux"
import Head from "components/Head"
import { useEffect, useState } from "react"
import type { ReactElement } from "react"
import type { GetNoteResp } from "@types"
import PublicLayout from "components/layouts/PublicLayout"
import { selectCurrentNote } from "features/noteSlice"
import { selectBlog } from "features/blogSlice"
import { GetStaticProps } from "next"
import Script from "next/script"
import { useRouter } from "next/router"
import useNoteUrl from "hooks/useNoteUrl"
import { getStaticProps as _getStaticProps } from "util/ssr/notePage"
import usePostPageMetrics from "hooks/postPage/usePostPageMetrics"
import GatedPostSkeleton from "components/public/BlogTheme/PostPage/PostSkeleton/GatedPostSkeleton"
import UngatedPostSkeleton from "components/public/BlogTheme/PostPage/PostSkeleton/UngatedPostSkeleton"

import { FrameResponseGenerator } from "@/util/frames"
import { getCollectibleImageUrl } from "@/util/crypto"
import { Collectible } from "@/types/highlights"
import consoleLogger from "@/util/console-logger"
import { RESPONSES } from "@/util/frames/types"
import { baseDomain } from "@/util/frames/helpers"

export const getStaticProps: GetStaticProps = _getStaticProps

export async function getStaticPaths() {
  // Disable static path generation for now
  if (process.env.VERCEL_ENV !== "production" || true) {
    return {
      paths: [],
      fallback: "blocking",
    }
  }
}

type Props = {
  description: string
  previewImage: string
  collectible: Collectible | Record<string, never>
  preview?: boolean
}

export default function BlogNoteIdPageComponent({
  description,
  previewImage,
  preview = false,
}: Props) {
  const router = useRouter()
  const blog = useSelector(selectBlog)

  const [postKey, setPostKey] = useState("")
  const currentNote = useSelector(selectCurrentNote) as GetNoteResp | undefined
  const { fullNoteUrl } = useNoteUrl({ blog, note: currentNote })

  usePostPageMetrics({ currentNote, blog, preview })

  const isTruncated =
    currentNote?.isPostGateTruncated || currentNote?.isAfterGateTruncated

  useEffect(() => {
    const key = `id-${currentNote?.id}-truncated-${isTruncated}-${
      router.query?.noteId as string
    }`

    console.log("Setting post key: " + key)
    setPostKey(key)
  }, [currentNote?.id, router.query?.noteId, isTruncated])

  const domain = baseDomain(blog)

  // If cover image set for note -> Use for social shares (except Frames)
  // In Head, if this is null, it will fallback to blog logo and then user avatar
  previewImage = currentNote?.cover_img?.img.src || ""

  /**
   * This is used for Frame share
   */
  let framePreview = getCollectibleImageUrl({
    text: currentNote?.title || "",
    post: currentNote,
    blog: blog,
    blogOwnerUser: blog.user,
    collectibleType: "POST",
    alwaysGenerate: true,
    size: 2048,
  })
  framePreview = `${domain}${framePreview}`

  /**
   * Generates the frame tags for the post page, used when embedding this link in Farcaster, XMTP, etc.
   * @returns The frame tags for the post page.
   */
  function FrameTags() {
    if (!currentNote) return null

    if (blog.user === undefined) {
      throw new Error(
        "Blog user is undefined. Cannot instantiate frame response generator without the blog user."
      )
    }

    const frameResponseGenerator = new FrameResponseGenerator({
      logger: consoleLogger,
      blog,
      blogOwnerUserId: blog.user.id,
      post: currentNote,
    })

    // Generate the frame response for the post page.
    const frameResponse = frameResponseGenerator.generateFrontEndFrameMetaTags({
      type: RESPONSES.HOMEPAGE_POST,
      image: framePreview,
      // isPostLevelGate: currentNote.isPostGateTruncated, // TODO: When we have post-level gates, pass this in.
    })

    return (
      <>
        {frameResponse.map((tag) => (
          <meta
            key={tag.property}
            property={tag.property}
            content={tag.content}
          />
        ))}
      </>
    )
  }

  // TODO: Only load Youtube embed JS if Youtube embed in note
  return (
    <div className="flex-grow">
      <Script src="/js/lite-youtube-embed.js" strategy="lazyOnload" />
      <Head
        blog={blog}
        title={`${blog.name} | ${currentNote?.title}`}
        share_title={currentNote?.title}
        fullUrl={fullNoteUrl}
        description={description}
        share_img={previewImage}
        canonicalUrl={currentNote?.canonicalUrl}
        creator={blog?.social?.twitter}
      >
        <link
          title={`${blog.name} posts`}
          type="application/rss+xml"
          href={`/api/blogs/rss/${blog.url}`}
        />

        <FrameTags />
      </Head>
      {currentNote?.isPostGateTruncated || currentNote?.isAfterGateTruncated ? (
        <GatedPostSkeleton
          blog={blog}
          key={postKey}
          currentNote={currentNote}
        />
      ) : (
        <UngatedPostSkeleton
          blog={blog}
          key={postKey}
          currentNote={currentNote as GetNoteResp}
        />
      )}
    </div>
  )
}

export const GetLayout = (page: ReactElement) => {
  return <PublicLayout showProgressBar>{page} </PublicLayout>
}

BlogNoteIdPageComponent.getLayout = GetLayout
