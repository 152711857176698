import { useEffect } from "react"
import type { GetNoteResp, Blog } from "@types"
import PostSkeleton from "./"
import useDebounce from "hooks/useDebounce"
import Placeholder from "components/Placeholder"
import useGatingLogic from "hooks/postPage/useGatingLogic"
import { getPostGatingRuleGroups } from "features/gatingRuleGroupsSlice"
import { useAppDispatch } from "store"

export default function GatedPostSkeleton({
  blog,
  currentNote,
}: {
  blog: Blog
  currentNote: GetNoteResp
}) {
  const { isLoading, hasPostAccess, postGatingRuleGroups } = useGatingLogic()

  // Since for gated posts there can be multiple API calls for truncated and un-truncated versions of the same post in quick succession,
  // leading to poor UX, let's add a debounce and wait until all the gating rule checks have stabilized before doing a final load on the post.
  const debouncedIsLoading = useDebounce(isLoading, 1000)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPostGatingRuleGroups(currentNote.id))
  }, [])

  if (debouncedIsLoading || !currentNote) {
    return (
      <div className="mt-24 flex justify-center">
        <Placeholder />
      </div>
    )
  }

  return (
    <PostSkeleton
      loading={debouncedIsLoading}
      currentNote={currentNote}
      blog={blog}
      hasAccess={hasPostAccess || false}
      gatingRuleGroups={postGatingRuleGroups || []}
      hideComments={!!blog.disable_comments}
    />
  )
}
