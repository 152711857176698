import { useEffect, useRef } from "react"
import { useUserActiveOnPage } from "./useUserActiveOnPage"
import { usePageVisibility } from "./usePageVisibility"

// In milliseconds.
const activityCheckInterval = 2000

export function useTimeOnPage(callback: (finalTimeOnPage: number) => void) {
  const isPageVisible = usePageVisibility()
  const isUserActive = useUserActiveOnPage()
  const timeOnPage = useRef(0)

  useEffect(() => {
    // When the user refreshes the page, clicks the back button, or closes the tab or browser, this gets called.
    window.onbeforeunload = () => {
      callback(timeOnPage.current)
    }
  }, [])

  useEffect(() => {
    // Every few seconds, let's check whether page is still visible and if the user has interacted with the page in some way.
    // If they have not, stop the timer. If they have, restart the timer.
    // We're using this instead of a useEffect hook because you can't use a ref from useRef (which is what isUserActive is) as a dependency.
    const inactivityInterval = setInterval(() => {
      // If the page becomes visible and the user is active (not idling), increment timer.
      if (isPageVisible.current && isUserActive.current) {
        // console.log("useTimeOnPage: Page is visible and user is active!", {
        //   timeOnPage: timeOnPage.current,
        //   isPageVisible: isPageVisible.current,
        //   isActive: isUserActive.current,
        // })

        timeOnPage.current = timeOnPage.current + activityCheckInterval / 1000 // Add the interval in seconds.
      } else {
        // console.log("useTimeOnPage: Page is invisible or user is inactive.", {
        //   timeOnPage: timeOnPage.current,
        //   isPageVisible: isPageVisible.current,
        //   isActive: isUserActive.current,
        // })
      }
    }, activityCheckInterval)

    // When the component dismounts, if the page is still visible, do one final time increment and then call the callback function.
    return () => {
      // console.log("useTimeOnPage: Exiting page!", timeOnPage.current)

      callback(timeOnPage.current)

      clearInterval(inactivityInterval)
    }
  }, [])

  return timeOnPage
}
