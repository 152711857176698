import { GatingContentType } from "@/types/gatingRules"
import { useState, useEffect } from "react"
import useGatingRulesForReader from "./useGatingRulesForReader"

// Custom hook that checks whether a user has access to a particular post.
// Returns true if they do, and false if they don't.
export default function useCheckGatingRulesHasAccess(
  postId: string,
  contentType: GatingContentType,
  requestCameFrom: string // Purely for logging purposes.
): boolean | undefined {
  const gatingRuleGroups = useGatingRulesForReader(
    postId,
    contentType,
    null,
    requestCameFrom
  )
  const [hasAccess, setHasAccess] = useState<boolean | undefined>()

  useEffect(() => {
    if (gatingRuleGroups == undefined) {
      console.log(
        `GatingRules: useCheckGatingRulesHasAccess found gating rules for post with ID ${postId}. Request came from ${requestCameFrom}. Setting access to undefined.`,
        gatingRuleGroups
      )
      setHasAccess(undefined)
      return
    }

    // If at least one of the gating rule groups grant access, then grant access in general.
    const hasOverallAccess =
      gatingRuleGroups.length == 0
        ? true // If no gating rules, set access to true.
        : gatingRuleGroups.some(
            // Otherwise set access based on permissions.
            (gr) => gr.hasAccess && gr.contentType == contentType
          )

    console.log(
      `GatingRules: useCheckGatingRulesHasAccess found ${
        gatingRuleGroups.flatMap((g) => g.enrichedGatingRules).length
      } gating rules for post with ID ${postId}. Request came from ${requestCameFrom}. Setting access to ${hasOverallAccess}.`,
      gatingRuleGroups
    )
    setHasAccess(hasOverallAccess)
  }, [postId, gatingRuleGroups, contentType, requestCameFrom])

  return hasAccess
}
