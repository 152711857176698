import { useEffect, useRef } from "react"

// Used to track whether this page is actively visible to the user or if they've navigated away to another tab.
export function usePageVisibility() {
  const isVisible = useRef(isDocumentVisible())
  const onVisibilityChange = () => {
    // console.log(
    //   "usePageVisibility: Screen visibility is ",
    //   isDocumentVisible(),
    //   isVisible.current
    // )
    isVisible.current = isDocumentVisible()
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange, false)

    return () => {
      // Remove event listeners in destructor.
      document.removeEventListener("visibilitychange", onVisibilityChange)
    }
  }, [])

  return isVisible
}

function isDocumentVisible() {
  return (
    typeof document !== "undefined" && document.visibilityState === "visible"
  )
}
