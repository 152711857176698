import { useEffect, useState } from "react"

import { useAnalytics } from "hooks/useAnalytics"

import { retrieveNote, selectCurrentNote } from "features/noteSlice"

import useGatingRulesForReader from "hooks/useGatingRulesForReader"
import useCheckGatingRulesHasAccess from "hooks/useCheckGatingRulesHasAccess"
import { AFTER_GATE_REGEX } from "@/util/gatingRules"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import { useRouter } from "next/router"
import { useAppDispatch } from "store"

export default function useGatingLogic(previewPage = false) {
  const currentNote = useSelector(selectCurrentNote)
  const blog = useSelector(selectBlog)
  const router = useRouter()
  const blogName = router.query?.blogname as string
  const noteId = router.query?.noteId as string
  const dispatch = useAppDispatch()

  const isTruncated =
    currentNote?.isPostGateTruncated || currentNote?.isAfterGateTruncated

  const [isLoading, setIsLoading] = useState(
    // If the initial note is truncated, then let's set isLoading to true. Otherwise the note is already loaded fully so nothing to do.
    isTruncated == undefined ? false : isTruncated
  )

  const { track } = useAnalytics()
  const postGatingRuleGroups = useGatingRulesForReader(
    currentNote?.id || "",
    "POST",
    null,
    "[noteId]"
  )

  const hasPostAccess = useCheckGatingRulesHasAccess(
    currentNote?.id || "",
    "POST",
    "[noteId]"
  )

  console.log(
    "AFTER GATE: postGatingRuleGroups, hasPostAccess",
    postGatingRuleGroups,
    hasPostAccess
  )

  const afterGatingRuleGroups = useGatingRulesForReader(
    currentNote?.id || "",
    "AFTER",
    null,
    "[noteId]"
  )

  console.log("AFTER GATE: afterGatingRuleGroups", afterGatingRuleGroups)

  // Check if the current AFTER gates should have access
  const afterGate = currentNote?.staticHtml?.match(AFTER_GATE_REGEX)
  let hasAfterAccess = false
  if (afterGate) {
    console.log("AFTER GATE: REGEX ", afterGate, currentNote?.staticHtml)
    const embedId = afterGate[1]
    console.log("embedId", embedId)

    // Get hasAccess for embedId and if not found, default to true
    const afterDecision = afterGatingRuleGroups
      ? afterGatingRuleGroups.find(
          (embedAccess) => embedAccess.embedId === embedId
        )?.hasAccess
      : true
    hasAfterAccess = afterDecision === undefined || afterDecision ? true : false

    console.log(
      "AFTER GATE: EMBEDID ",
      embedId,
      afterGatingRuleGroups,
      afterGatingRuleGroups?.find(
        (embedAccess) => embedAccess.embedId === embedId
      )?.hasAccess,
      hasAfterAccess
    )
  }

  // Track user gated post views.
  useEffect(() => {
    // If there were at least some sort of gating rules setup.
    if (
      (postGatingRuleGroups && postGatingRuleGroups.length > 0) ||
      (afterGatingRuleGroups && afterGatingRuleGroups.length > 0)
    ) {
      // Then track gating related data.
      track("user viewed gated post", {
        postGate: postGatingRuleGroups && postGatingRuleGroups.length > 0,
        gatingEmbed: afterGatingRuleGroups && afterGatingRuleGroups.length > 0,
        hasPostAccess,
        hasAfterAccess,
        postGatingRuleGroups,
        afterGatingRuleGroups,
      })
    }
  }, [
    JSON.stringify(postGatingRuleGroups),
    JSON.stringify(afterGatingRuleGroups),
    hasPostAccess,
    hasAfterAccess,
  ])

  useEffect(() => {
    if (!isTruncated) {
      console.log(
        "GatingRules: Current post is not truncated, so doing nothing."
      )
      return // Do nothing for un-truncated (full) posts.
    }
    if (!blog || !noteId) {
      console.log("GatingRules: Blog or noteId is not set, so doing nothing.")
      return
    }

    if (
      currentNote &&
      (!postGatingRuleGroups || postGatingRuleGroups.length === 0) &&
      (!afterGatingRuleGroups || afterGatingRuleGroups.length === 0)
    ) {
      console.log(
        `GatingRules: Current post ${currentNote.id} is fully loaded as there are no gating rules, so doing nothing.`,
        postGatingRuleGroups
      )
      setIsLoading(false)
      return
    }

    console.log(
      "GatingRules: Current post did have gating rules, so it's likely we're dealing with a truncated post. "
    )

    if (!hasPostAccess && !hasAfterAccess) {
      console.log(
        "GatingRules: However, user does not have access, so no point refreshing to get the full post."
      )
      setIsLoading(false)
      return
    }

    console.log(
      "GatingRules: Moreover, user should have access. " +
        "Let's refresh using an authenticated fetch to see if the user has access on the server-side.",
      afterGatingRuleGroups,
      hasPostAccess,
      hasAfterAccess
    )
    setIsLoading(true)

    dispatch(retrieveNote(blogName, noteId, previewPage)).then(() => {
      console.log(
        "GatingRules: NOTE has been retrieved so setting loading to false!",
        noteId,
        currentNote
      )
      setIsLoading(false)
    })
  }, [
    hasPostAccess,
    hasAfterAccess,
    JSON.stringify(postGatingRuleGroups),
    JSON.stringify(afterGatingRuleGroups),
    isTruncated,
  ])

  return { hasPostAccess, postGatingRuleGroups, hasAfterAccess, isLoading }
}
